import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Modal } from "react-bootstrap";
import $ from "jquery";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import getVideoId from 'get-video-id';
import PlayCustomVideo from "../../Play/custom-video";
import { People_Email } from "../../../queries/common_use_query"
import { gtag_report_conversion } from "../../../components/common/pageUtils"
import "./StickyNav.scss"

// Negotiator details
const NegoDetails = (email_id) => {

    const { loading, error, data } = People_Email(email_id);

    // console.log("nego_info ==>", data);
    return data;
}
// Negotiator details

function getOffset(el) {
    let _x = 0;
    let _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}

const StickyNav = (props) => {
    const stickyRef = useRef();

    useEffect(() => {
        // 
        var propertySelector = '.property-sticky-menu-list li a';
        $(propertySelector).on('click', function () {
            $(propertySelector).removeClass('active');
            $(this).addClass('active');
        });

        $(".propertyDesc").click(function () {
            $('html, body').animate({
                scrollTop: $("#property-details-description").offset().top - 100
            }, 1000);
        })

        $(".propertyGallery").click(function () {
            $('html, body').animate({
                scrollTop: $("#property-gallery").offset().top - 100
            }, 1000);
        })

        $(".propertyGallery").click(function () {
            $('html, body').animate({
                scrollTop: $("#property-gallery").offset().top - 100
            }, 1000);
        })
        $(".propertyDataRoom").click(function () {
            $('html, body').animate({
                scrollTop: $("#property-data-room").offset().top - 100
            }, 1000);
        })


        $(".propertyMap").click(function () {
            $('html, body').animate({
                scrollTop: $("#property-details-map").offset().top - 100
            }, 1000);
        })

        $(".enquireNow").click(function () {
            $('html, body').animate({
                scrollTop: $("#enquire-now").offset().top - 100
            }, 1000);
        })
        //

        window.addEventListener("scroll", windowScroll);
        return () => window.removeEventListener("scroll", windowScroll);
    }, []);

    // Property details sticky nav
    const windowScroll = () => {
        const top = getOffset(stickyRef.current).top + 650;
        const bottomSticky = getOffset(stickyRef.current).top + 2300;
        // const top = 650;
        // const bottomSticky = 1500;

        if (window.pageYOffset > top && window.pageYOffset < bottomSticky) {
            stickyRef.current.classList.add('is-sticked');
        } else {
            stickyRef.current.classList.remove('is-sticked');
        }
    }
    // Property details sticky nav

    // Floorplan
    const [floorIndex, setFloorIndex] = useState(0);
    const [isFloorOpen, setIsFloorOpen] = useState(false);

    const floorplanImages = props?.propertyData?.floorplan
    var floorLightbox = floorplanImages && floorplanImages.map(floorImg => floorImg.url);
    if (floorLightbox && floorLightbox.length > 0 && floorLightbox[0] == null) {
        floorLightbox = floorplanImages && floorplanImages.map(floorImg => floorImg.srcUrl);
    }
    const clickFloorplan = () => {
        var fileNameFloor = "";
        if (props?.propertyData?.floorplan[0]?.url) {
            fileNameFloor = props?.propertyData?.floorplan[0]?.url;
        } else if (props?.propertyData?.floorplan[0]?.srcUrl) {
            fileNameFloor = props?.propertyData?.floorplan[0]?.srcUrl;
        }
        if (fileNameFloor) {
            var fileextensionFloor = fileNameFloor.split('.').pop();
            if (fileextensionFloor === "pdf") {
                window.open(fileNameFloor);
            } else {
                setIsFloorOpen(true);
            }
        }
    }
    // Floorplan

    // Video
    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }
    // Video

    // Video id


    // const videoid = props?.propertyData && props?.propertyData?.virtual_tour && props?.propertyData?.virtual_tour.length > 0 && getVideoId(props?.propertyData?.virtual_tour[0]);
    // Video id

    // Negotiator details
    var nego_info = props?.propertyData?.crm_negotiator_id && props?.propertyData?.crm_negotiator_id[0]?.email && NegoDetails(props?.propertyData?.crm_negotiator_id[0]?.email);
    // Negotiator details

    return (
        <React.Fragment>
            {
                props?.propertyData &&
                <div className={`property-sticky-menu  ${(props?.propertyData?.status === "Sold" || props?.propertyData?.status === "let") ? "min_hght" : ""}`} ref={stickyRef}>
                    <Container>
                        <Row>
                            <Col xs={12} className="d-flex align-items-center col-md-7">
                                <ul className="list-inline property-sticky-menu-list">
                                    {
                                        props?.propertyData?.description &&
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" className="active sticky-link propertyDesc">Features and description</a>
                                        </li>
                                    }
                                    {
                                        props?.propertyData?.latitude &&
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" className="sticky-link propertyMap">Location</a>
                                        </li>
                                    } 
                                    <li className="list-inline-item">
                                        <a href="javascript:void(0)" className="sticky-link propertyGallery">Gallery</a>
                                    </li>
                                    {props?.propertyData?.extra?.floor_units &&
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" className="sticky-link propertyDataRoom">DataRoom</a>
                                        </li>
                                    } 
                                    
                                </ul>
                            </Col>
                              <Col className="d-none d-lg-flex justify-content-end col-md-5">
                                {props?.propertyData?.status !== "Sold" && props?.propertyData?.status !== "let" &&
                                    <ul className="list-inline property-menu-sticky-call d-flex align-items-center">
                                        {
                                            nego_info?.peoples?.length > 0 &&
                                            <li className="list-inline-item sticky-call">
                                                Call <a href={"tel:" + nego_info?.peoples[0]?.Phone_Number} className="">{nego_info?.peoples[0]?.Phone_Number}</a>
                                            </li>
                                        }

                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" onClick={() => gtag_report_conversion()} className="btn m-0 enquireNow">Enquire Now</a>
                                        </li>

                                    </ul>
                                }

                            </Col> 
                        </Row>
                    </Container>

                    {/* Floorplan */}
                    {isFloorOpen && floorLightbox && (
                        <Lightbox
                            mainSrc={floorLightbox[floorIndex]}
                            nextSrc={floorLightbox[(floorIndex + 1) % floorLightbox.length]}
                            prevSrc={floorLightbox[(floorIndex + floorLightbox.length - 1) % floorLightbox.length]}
                            onCloseRequest={() => setIsFloorOpen(false)}
                            onMovePrevRequest={() =>
                                setFloorIndex((floorIndex + floorLightbox.length - 1) % floorLightbox.length)
                            }
                            onMoveNextRequest={() =>
                                setFloorIndex((floorIndex + 1) % floorLightbox.length)
                            }
                        />
                    )}
                    {/* Floorplan */}

                    {/* Video modal */}
                    <Modal
                        show={show}
                        fullscreen={fullscreen}
                        onHide={() => setShow(false)}
                        dialogClassName="modal-fullscreen property-details-modal"
                    >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            <iframe src={props?.propertyData && props?.propertyData?.virtual_tour && props?.propertyData?.virtual_tour.length > 0 && props?.propertyData?.virtual_tour[0].url}></iframe>
                        </Modal.Body>
                    </Modal>
                    {/* Video modal */}

                </div>
            }
        </React.Fragment>
    )
}

export default StickyNav