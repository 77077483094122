import React, { useState, useEffect } from "react"; 
import { useQuery } from '@apollo/client';
import Modal from "react-bootstrap/Modal"
import gql from "graphql-tag"
import $ from "jquery";
import { Helmet } from "react-helmet" 
import PropertyNav from "../components/PropertyDetails/PropertyNav";
import StickyNav from "../components/PropertyDetails/StickyNav/CommercialStickyNav" 
import Avenue from "../components/PropertyDetails/Avenue/CommercialAvenue";
import Banner from "../components/PropertyDetails/Banner/Banner";
import Gallery from "../components/PropertyDetails/Gallery/Gallery";
import Description from "../components/PropertyDetails/PropertyDescription/CommercialDescription";
import AvailabilityAccommodation from "../components/PropertyDetails/PropertyDescription/AvailabilityAccommodation";
import Connect from "../components/PropertyDetails/Connect/CommercialConnect";
import LocRatingMap from "../components/map/loc-rating-map-details"
import BrandLogo from "../images/logo002.svg";
import MemberForm from "../components/forms/member-form"
import Layout from "../components/layout";
import SEO from "../components/seo";

import { propertyDetH1, propertyDetDesc, propertyDetImage } from "../components/property-search/propertyDetailsSEO";
import EnquirySticky from "../components/PropertyDetails/StickyNav/EnquirySticky";
import NotFoundPage from "../pages/404";
import ContactVendor from "../components/PropertyDetails/ContactVendor/ContactVendor";
import "../scss/commercial-property.scss";
const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    properties(where:{id: $id, publish:true}) {
      id
      crm_id
      crm_provider
      status
      floorarea_max
      floorarea_min
      floorarea_type
      building
      search_type
      department
      building
      address
      display_address
      title
      price
      max_price
      auctionType
      auctionDate
      auctionId
      bedroom
      bathroom
      floorplan
      images
      imagetransforms
      virtual_tour
      accommodation_summary
      crm_negotiator_id
      description
      long_description
      latitude
      longitude
      introtext
      extra
      brochure
      price_qualifier
      postcode
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
    }

    globalModule {
        Auction_Details {
            Joint_Auctioneer
            Make_An_Offer
            Notice_Bidder_Content
            Purchase_Legal_Summary
            Register_To_Bid
            View_Legal_Documents
            id
        }
    }
  }
`;

const CommercialPropertyDetailsTemplate = (props) => {

    const [search, setSearch] = useState(false);
    const [open, setOpen] = useState(false);
    const [menu_heading, setMenuHeading] = useState(false);
    const [state, setState] = React.useState({
        showMenu: false,
    });

    const [isOpen, setIsOpen] = useState(false);
    const [membDet, setMembDet] = useState('');
    const [popupType, setPopupType] = useState('');

    const MemberPopup = (item, popupType) => {
        setPopupType(popupType);
        setMembDet(item);
        setIsOpen(true);
    }

    useEffect(() => {
        $("body").removeClass("overflow-hidden")
    })

    // 
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    var page_url = props.location?.state?.page_url ? props.location?.state?.page_url : "";

    const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
        variables: { id: id }
    });

    // 

    // Header handle click
    const handlerClick = () => {
        setState({ ...state, showMenu: !state.showMenu });
        if (state.showMenu == true) {
            document.body.className = "";
        } else {
            document.body.classList.add("overflow-hidden");
        }
    };
    // Header handle click

    const openHam = () => {
        setOpen(!open);
    };

    const openSearch = (menu_title) => {
        setSearch(!search);
        handlerClick();
        setMenuHeading(menu_title)
    };
    const closeSearch = () => {
        setSearch(!search);
        handlerClick();
    };

    var lot_no = props.location?.state?.lot_no ? props.location?.state?.lot_no : "";

    var pop_slug = "buying";
    if (property_details?.properties[0]?.department === "residential" && property_details?.properties[0]?.search_type === "sales") {
        pop_slug = "buying";
    } else if (property_details?.properties[0]?.department === "residential" && property_details?.properties[0]?.search_type === "lettings") {
        pop_slug = "renting";
    } else if (property_details?.properties[0]?.department === "commercial") {
        pop_slug = "commercial";
    } else if (property_details?.properties[0]?.department === "auction_commercial" || property_details?.properties[0]?.department === "auction_residential") {
        pop_slug = "auctions";
    }

    var meta_title = property_details?.properties?.length > 0 ? propertyDetH1(property_details?.properties[0]) : "";
    var meta_desc = property_details?.properties?.length > 0 ? propertyDetDesc(property_details?.properties[0]) : "";
    var meta_img = property_details?.properties?.length > 0 ? propertyDetImage(property_details?.properties[0]) : "";
    // Loading logo 
    if (loading) {
        return (
            <section className={"loader-wrapper"}>
                <div id="loader-wrapper">
                    <div id="loader" className="new-loader">
                        <img className="logo-white" src={BrandLogo} alt="logo" />
                    </div>
                </div>
            </section>
        );
    } else if (property_details?.properties && property_details?.properties?.length > 0) {
        return (
            <Layout GQLPage="" Layout="Without_Banner" classNames={`commercial-detail-page search-header person-header details-header ${pop_slug && `${pop_slug}-prop-details`}`} closeSearch={closeSearch} handlerClick={handlerClick} menu_heading={menu_heading} search={search} open={open} popular_search_slug={pop_slug}>

                {(property_details?.properties[0]?.department === "auction_residential" || property_details?.properties[0]?.department === "auction_commercial") &&
                    <Helmet>
                        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10810490027"></script>
                    </Helmet>
                }

                {property_details.properties.map((data, i) => {

                    // Get Map Details
                    let mapItems = [];
                    let mapItem = {};
                    mapItem['lat'] = data.latitude
                    mapItem['lng'] = data.longitude
                    mapItem['id'] = data.id
                    mapItems.push(mapItem);
                    // Get Map Details

                    var area = data.address?.address3 ? data.address?.address3 : data.address?.address2 ? data.address?.address2 : data.address?.address1;

                    var accord_accomada = data?.extra?.lotData?.filter(item => item.ShowOnWeb === true && (item.Value !== "False" && item.Value !== ""));

                    return (
                        <div key={i}>
                            <SEO title={meta_title} description={meta_desc} image={meta_img} />
                            <PropertyNav propertyData={data} page_url={page_url} />

                            {/* <ContactStickyNav propertyData={data} /> */}
                            <Avenue propertyData={data} lot_no={lot_no} globalModule={property_details?.globalModule?.Auction_Details} />
                            <StickyNav propertyData={data} />
                            <Banner propertyData={data} lot_no={lot_no} globalModule={property_details?.globalModule?.Auction_Details} />
                            <ContactVendor propertyVendor={data?.extra?.contacts} />
                            <Description propertyData={data} globalModule={property_details?.globalModule?.Auction_Details} />
                            <div className="container property-details-landing-map mt-5" id="property-details-map">
                                <LocRatingMap data={mapItems} sold_price_tab={data?.department === "commercial" ? false : true} />
                            </div>
                            <Gallery propertyData={data} lot_no={lot_no} globalModule={property_details?.globalModule?.Auction_Details} />
                            {data?.extra?.floor_units &&
                                <AvailabilityAccommodation propertyData={data} globalModule={property_details?.globalModule?.Auction_Details} />
                            }

                            {data?.status?.toLowerCase() !== "sold" && data?.status?.toLowerCase() !== "let" &&
                                <Connect propertyData={data} openSearch={openSearch} openHam={openHam} search={search} open={open} location={props.location} globalModule={property_details?.globalModule?.Auction_Details} />
                            }
                            {/* <SimilarProperties propertyData={data} area={area} department={data.department} MemberPopup={MemberPopup} /> */}
                            <EnquirySticky />
                        </div>
                    )

                })}

                {
                    popupType &&
                    <Modal
                        id="popup_modal_form"
                        show={isOpen}
                        onHide={() => setIsOpen(false)}
                        backdrop="static"
                        keyboard={false}
                        className="member-contact-modal"
                    >
                        {
                            popupType === "Member_Form" && <>
                                <Modal.Header closeButton className="contact-close-btn memberPopup">
                                    <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {membDet.Name}</h3></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <MemberForm to_email_id={membDet.Email_ID} to_user_id={membDet.Form_Queue_ID} formName={`Individual Contact - ${membDet.Name}`} formLabel={`Person ${membDet.Name}`} />
                                </Modal.Body>
                            </>
                        }
                    </Modal>
                }
            </Layout>
        )
    } else {
        return (<NotFoundPage />)
    }
}

export default CommercialPropertyDetailsTemplate