import React from "react";
import { CommonReducer } from "./reducers/CommonReducer"
import { actions } from "./actions/dispatch"

const initialState = { filter_by_cat: "", filter_by_serv: "", filter_by_sec: "", filter_by_depart: "", filter_by_level: "", search_list_department: "", all_insights: []};

const ContainerContext = React.createContext(initialState);

export const ContainerContextProvider = props => {
  const [reducerState, dispatch] = React.useReducer(CommonReducer, initialState);
  const reducerActions = actions(dispatch);
  const context = {
    state: { ...reducerState },
    actions: { ...reducerActions }
  };

  return (
    <ContainerContext.Provider value={context}>
      {props.children}
    </ContainerContext.Provider>
  );
};

export const useContainerContext = () => React.useContext(ContainerContext);
