import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import HTMLReactParser from 'html-react-parser';
// Header component

const AvailabilityAccommodation = (props) => {
 
  return (
    <React.Fragment>
      {
        props?.propertyData &&
        <section className={`availability-accommodation ${props?.propertyData?.department === "auction_commercial" || props?.propertyData?.department === "auction_residential" ? "auction_desc" : ""}`} id="property-data-room">
          <Container>
            <Row>
              <Col>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="property-wrapper">

                    <div className="accommodation-table-wrap">
                      <h2>Availability/Accommodation </h2>
                      <div class="accommodation-table-container">
                        <table class="table lot-table"> 
                          <thead>
                            <tr>
                              <th class="col-0">Floor Unit</th>
                              <th class="col-1">Property type</th>
                              <th class="col-2">size</th> 
                              <th class="col-2">Rent SqFt</th> 
                              <th class="col-4">Rent p.a.</th>
                            </tr>
                          </thead> 
                          <tbody>
                            {props?.propertyData?.extra?.floor_units.length > 0 && props?.propertyData?.extra?.floor_units.map((data, k)=>{
                              return(
                                <tr class="row-1 odd" key={k}>
                                <td class="col-0">{data.floor_unit}</td>
                                <td class="col-1">{data.marketing_columns?.buildingType}</td>
                                <td class="col-2">{data.marketing_columns?.size}</td> 
                                <td class="col-4">{data.marketing_columns?.rentSqFt}</td>
                                <td class="col-4">{data.marketing_columns?.totalYear}</td>
                              </tr>
                              )
                            })} 
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </Col>
            </Row>
          </Container>
        </section>
      }
    </React.Fragment>
  );
};
export default AvailabilityAccommodation;
