import { 
    FilterByCat,
    FilterByService,
    FilterBySector,
    FilterByDepartment,
    FilterByLevels,
    AllInsights,
    SearchListDepart
} from "./actionCreator"

export const actions = dispatch => ({
    FilterByCatDispatch: (data) => dispatch(FilterByCat(data)),
    FilterByServiceDispatch: (data) => dispatch(FilterByService(data)),
    FilterBySectorDispatch: (data) => dispatch(FilterBySector(data)),
    FilterByDepartmentDispatch: (data) => dispatch(FilterByDepartment(data)),
    FilterByLevelsDispatch: (data) => dispatch(FilterByLevels(data)),
    AllInsightsDispatch: (data) => dispatch(AllInsights(data)),
    SearchListDepartDispatch: (data) => {dispatch(SearchListDepart(data))}
});