import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Slider from "react-slick"; 
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import getVideoId from 'get-video-id'; 
import NoImage from "../../../images/no-image.png"
import ScrollAnimation from "react-animate-on-scroll";
import { People_Email } from "../../../queries/common_use_query" 
import {ImageModule} from "../../../modules/Image_Module"  

import { getPropAddr } from "../../property-search/utils"

import PlayVideo from '../../Play/PlayVideo';
import _ from "lodash";

import "./Banner.scss" 

// Negotiator details
const NegoDetails = (email_id) => {

    const { loading, error, data } = People_Email(email_id);

    // console.log("nego_info ==>", data);
    return data;
}
// Negotiator details

const Banner = (props) => {

    // Property details image count
    const [indexed, setIndex] = useState(1);
    const [isTodayAuction, setIsTodayAuction] = useState(false);

    const afterChangeHandler = (d) => {
        setIndex(d + 1)
    }
    // Property details image count

    let [nav1, slider1] = useState(null)
    let [nav2, slider2] = useState(null);

    const nav_settings = {
        slidesToShow: 7,
        swipeToSlide: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1799,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                },
            },
        ],
    };

    const slider_settings = {
        slidesToShow: 1,
        swipeToSlide: true,
        arrows: true, 
        // prevArrow: <span className="prev_arw">&lt;ss</span>,
        // nextArrow: <span className="next_arw">&gt;aa</span>,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: true,
                    autoplay: true,
                    pauseOnHover: false
                },
            },
        ],
    };

    const formattedDate = (currentDate)=>{
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    useEffect(() => {


        // Future auction date count
        if (props?.propertyData?.department === "auction_residential" || props?.propertyData?.department === "auction_commercial") {

            // if(Date.parse(props?.propertyData?.auctionDate) < new Date().getTime()){
            //     console.log("start is less than end", Date.parse(props?.propertyData?.auctionDate), new Date().getTime())
            //  }else{
            //     console.log("end is less than start", Date.parse(props?.propertyData?.auctionDate), new Date().getTime())
            //  }

            if (props?.propertyData && props?.propertyData?.auctionType === "future" && props?.propertyData?.auctionDate) {

                var futureDays = document.getElementById('futureDays');
                var futureHours = document.getElementById('futureHours');
                var futureMins = document.getElementById('futureMins');
                var futureSeconds = document.getElementById('futureSeconds');

               
                //today is auction date
                if(formattedDate(new Date()) === formattedDate(new Date(props?.propertyData?.auctionDate))){
                    setIsTodayAuction(true)
                }
                if (Date.parse(props?.propertyData?.auctionDate) > new Date().getTime()) {
                    var countDownDate = new Date(props?.propertyData?.auctionDate).getTime(); 
                    var x = setInterval(function () {
                        var currentDate = new Date().getTime();
                        var distance = countDownDate - currentDate; 

                        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                        if(typeof futureDays !== null && futureDays !== 'undefined' ) {
                            futureDays.innerHTML = days;
                        }
                        if(typeof futureHours !== null && futureHours !== 'undefined' ) {
                            futureHours.innerHTML = hours;
                        }if(typeof futureMins !== null && futureMins !== 'undefined' ) {
                            futureMins.innerHTML = minutes;
                        }if(typeof futureSeconds !== null && futureSeconds !== 'undefined' ) {
                            futureSeconds.innerHTML = seconds;
                        }
                        // document.getElementById("futureDays").innerHTML = days;
                        // document.getElementById("futureHours").innerHTML = hours;
                        // document.getElementById("futureMins").innerHTML = minutes;
                        // document.getElementById("futureSeconds").innerHTML = seconds;
                    }, 1000);
                } else {
                   
                    if(typeof futureDays !== null && futureDays !== 'undefined' ) {
                        futureDays.innerHTML = 0;
                    }
                    if(typeof futureHours !== null && futureHours !== 'undefined' ) {
                        futureHours.innerHTML = 0;
                    }if(typeof futureMins !== null && futureMins !== 'undefined' ) {
                        futureMins.innerHTML = 0;
                    }if(typeof futureSeconds !== null && futureSeconds !== 'undefined' ) {
                        futureSeconds.innerHTML = 0;
                    }
                    // document.getElementById("futureDays").innerHTML = 0;
                    // document.getElementById("futureHours").innerHTML = 0;
                    // document.getElementById("futureMins").innerHTML = 0;
                    // document.getElementById("futureSeconds").innerHTML = 0;
                }

            }
        }
        // Future auction date count
    }, [props])

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);   

    const openPropertyImage = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }
    // Property images lightbox

    // Property details images lightbox
    const propertyImages = props?.propertyData?.images
    var propertyLightImages = propertyImages && propertyImages.map(img => img.srcUrl);
    // Property details images lightbox

    // Video
    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    const [isPlay, setPlay] = useState(false);
    const [videoId, setVideoId] = useState('');
    const [videoUrl, setVideoUrl] = useState('');

    const [availableLots, setAvailableLots] = useState((sessionStorage.getItem('avaiable_lots') !== null) ? JSON.parse(sessionStorage.getItem('avaiable_lots')) : []);
    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }
    // Video

    const setVirtualTour = (video_url) => {

        var video_url2 = video_url && getVideoId(video_url);

        if (video_url2?.service === "youtube" || video_url2?.service === "vimeo") {
            setVideoUrl(video_url.replace("https://vimeo.com/manage/videos/", "https://vimeo.com/"));
            setPlay(true);
        } else {
            typeof window !== "undefined" && window.open(video_url);
        }
        // youtube_parser(video_url) ? setVideoId(youtube_parser(video_url)) : setVideoUrl(video_url); 

    }
    const renderTourBtn = (video_url) => {

        var video_url2 = video_url && getVideoId(video_url);

        if (video_url2?.service === "youtube" || video_url2?.service === "vimeo") {
           return true;
        }else{
            return false;
        }
       

    }

    // Floorplan
    const [floorIndex, setFloorIndex] = useState(0);
    const [isFloorOpen, setIsFloorOpen] = useState(false);

    const floorplanImages = props?.propertyData?.floorplan
    var floorLightbox = floorplanImages && floorplanImages.map(floorImg => floorImg.url);
    if(floorLightbox && floorLightbox.length > 0 && floorLightbox[0]==null) {
        floorLightbox = floorplanImages && floorplanImages.map(floorImg => floorImg.srcUrl);
    }

    const clickFloorplan = (e, ind) => {
        var fileNameFloor = "";
        if(props?.propertyData?.floorplan[0]?.url) {
            fileNameFloor = props?.propertyData?.floorplan[0]?.url;
        } else if(props?.propertyData?.floorplan[0]?.srcUrl) {
            fileNameFloor = props?.propertyData?.floorplan[0]?.srcUrl;
        }
        if(fileNameFloor) {
            var fileextensionFloor = fileNameFloor.split('.').pop();
            if(fileextensionFloor === "pdf") {
                window.open(fileNameFloor);
            } else {
                setIsFloorOpen(true);
            }
        }
    }
    // Floorplan

    //console.log("virtual_tour", props?.propertyData?.virtual_tour);

    // Video id
    // const videoid = props?.propertyData && props?.propertyData?.virtual_tour && props?.propertyData?.virtual_tour.length > 0 && props?.propertyData?.virtual_tour[0].url && getVideoId(props?.propertyData?.virtual_tour[0].url);
    // Video id

    // Negotiator details
    var nego_info = props?.propertyData?.crm_negotiator_id && props?.propertyData?.crm_negotiator_id[0]?.email && NegoDetails(props?.propertyData?.crm_negotiator_id[0]?.email);
    // Negotiator details

    //console.log("auctionType", props?.propertyData?.auctionType);

    var addr = props?.propertyData?.display_address ? getPropAddr(props?.propertyData?.display_address, props?.propertyData?.postcode) : "";

    var virtual_tour = props?.propertyData?.extra?.lotData && props?.propertyData?.extra?.lotData.filter(item => item.Name === "Virtual Viewing Video Link")

    var virtual_tour_comm_res = props?.propertyData && props?.propertyData?.virtual_tour && props?.propertyData?.virtual_tour.length > 0 && props?.propertyData?.virtual_tour[0].url;

    var earlyInvestment = false;
    if (props?.propertyData.auctionId && availableLots.length > 0) {
        if (availableLots[2].future_lot_id.includes(props.auctionId) && props?.propertyData?.status == "NoBids") {
            earlyInvestment = true;
        }
    }

    var crm_provider = props?.propertyData?.crm_provider;

    return (
        <>
            {
                props?.propertyData &&
                <section className="avenue banner_section">
                   
                        <Row>
                            <Col>
                                <div className="avenue-wrapper">
                                    <div className="banner-slider slide-img">
                                        <ScrollAnimation animateIn='fadeInRight' animateOnce={true}>
                                            <Slider
                                                asNavFor={nav2}
                                                ref={slider => slider1(slider)}
                                                afterChange={
                                                    v => afterChangeHandler(v)
                                                }
                                                {...slider_settings}
                                                className="main_slider"
                                            >
                                                {
                                                    props?.propertyData?.images.length > 0 ?
                                                        props?.propertyData?.images.map((bannerImg, i) => {                                                           

                                                            return (
                                                                <div className="nav-img" key={i}>  
                                                                    {bannerImg?.url != null ? (
                                                                        <ImageModule
                                                                        ImageSrc={bannerImg}
                                                                        imagename="property.images.banner_detail"
                                                                        ggfx_results={props?.propertyData?.ggfx_results} 
                                                                        strapi_id={props.propertyData.id} />
                                                                    ) : (bannerImg?.srcUrl != null && crm_provider == "ams" || crm_provider == "agentsinsight")
                                                                        ? <img src={bannerImg?.srcUrl} alt={props?.propertyData?.display_address + " -  Strettons"} />
                                                                        : <img src={NoImage} alt="img" />
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className="nav-img">
                                                            <img src={NoImage} alt="img" />
                                                        </div>
                                                }


                                            </Slider> 
                                            <div className="img-icon">
                                                {
                                                    props?.propertyData?.images.length > 0 &&
                                                    <>
                                                        <span className="icon-text" onClick={(e) => openPropertyImage(e, 0)}>
                                                            <i className="icon-zoom"></i>
                                                            {indexed}/{props?.propertyData?.images.length}
                                                        </span>               
                                                    </>
                                                }

                                                {
                                                    props?.propertyData?.floorplan?.length > 0 &&
                                                    <span className="icon-text icon-hover" onClick={clickFloorplan}>
                                                        <i className="icon-floorplan"></i>
                                                        Floorplan
                                                    </span>
                                                }

                                                {props?.propertyData?.department === "auction_residential" || props?.propertyData?.department === "auction_commercial" ? (
                                                    virtual_tour &&
                                                    virtual_tour.length > 0 && virtual_tour[0].Value && renderTourBtn(virtual_tour[0].Value) &&
                                                    <span className="icon-text icon-hover" onClick={(e) => setVirtualTour(virtual_tour[0].Value)}>
                                                        <i className="icon-videocamera"></i>
                                                        <span className="d-none d-md-block">virtual tour</span>
                                                        <span className="d-block d-md-none">tour</span>
                                                    </span>
                                                ) :
                                                    (virtual_tour_comm_res && renderTourBtn(virtual_tour_comm_res) &&  
                                                        <span className="icon-text icon-hover" onClick={(e) => setVirtualTour(virtual_tour_comm_res)}>
                                                            <i className="icon-videocamera"></i>
                                                            <span className="d-none d-md-block">virtual tour</span>
                                                            <span className="d-block d-md-none">tour</span>
                                                        </span>
                                                    )


                                                    // (
                                                    // videoid?.service === "youtube" ?
                                                    // <PlayCustomVideo url={props?.propertyData?.virtual_tour} tag="pictureVideo" />
                                                    // :
                                                    // <span className="icon-text icon-hover" onClick={() => handleShow()}>
                                                    //     <i className="icon-videocamera"></i>
                                                    //     virtual tour
                                                    // </span>
                                                    // )
                                                }
                                            </div>
                                            {/* {
                                                props?.propertyData?.floorplan &&
                                                <div className="floor-plan d-none d-xl-block">
                                                    <img src={this.props?.propertyData?.floorplan[0]?.srcUrl} alt="img" />
                                                </div>
                                            } */}

                                            {
                                                props?.propertyData?.status && earlyInvestment === false &&
                                                <div className="offer-text">
                                                    {(_.toLower(props?.propertyData?.status).replace(" ", "").replace("-", "") != "nobids") &&
                                                        <>
                                                            {props?.propertyData?.status === "UNDER OFFER - For Sale" || props?.propertyData?.status === "UNDER OFFER - To Let" ? "UNDER OFFER" : props?.propertyData?.status.replace('ReferToAuctioneer', "Refer To Auctioneer").replace('Unsold', "For Sale")}
                                                        </>
                                                    }
                                                </div>
                                            }
                                            {earlyInvestment &&
                                                <div className="offer-text early_investment">
                                                    Early investment
                                                </div>
                                            }
                                        </ScrollAnimation>

                                    </div> 
                                </div>
                            </Col>
                        </Row> 
                    {/* Banner image popup */}
                    {isOpen && (
                        <Lightbox
                            mainSrc={propertyLightImages[photoIndex]}
                            nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
                            prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() =>
                                setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                            }
                        />
                    )}
                    {/* Banner image popup */}

                    {/* Floorplan */}
                    {isFloorOpen && floorLightbox && (
                        <Lightbox
                            mainSrc={floorLightbox[floorIndex]}
                            nextSrc={floorLightbox[(floorIndex + 1) % floorLightbox.length]}
                            prevSrc={floorLightbox[(floorIndex + floorLightbox.length - 1) % floorLightbox.length]}
                            onCloseRequest={() => setIsFloorOpen(false)}
                            onMovePrevRequest={() =>
                                setFloorIndex((floorIndex + floorLightbox.length - 1) % floorLightbox.length)
                            }
                            onMoveNextRequest={() =>
                                setFloorIndex((floorIndex + 1) % floorLightbox.length)
                            }
                        />
                    )}
                    {/* Floorplan */}

                    {/* Video modal */}
                    {/* <Modal
                        show={show}
                        fullscreen={fullscreen}
                        onHide={() => setShow(false)}
                        dialogClassName="modal-fullscreen property-details-modal"
                    >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            <iframe src={virtual_tour && virtual_tour?.length > 0 && virtual_tour[0].Value}></iframe>
                        </Modal.Body>
                    </Modal> */}
                    {/* Video modal */}

                    <PlayVideo
                        isOpen={isPlay}
                        isCloseFunction={setPlay}
                        videoId={videoId}
                        videoUrl={videoUrl}
                        isAutoPlay={1}
                    />

                </section>
            }
        </>
    )
}

export default Banner;
