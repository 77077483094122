import {FILTER_BY_CATEGORY, FILTER_BY_SERVICES, FILTER_BY_SECTORS, FILTER_BY_DEPARTMENTS, FILTER_BY_LEVELS, ALL_INSIGHTS, SEARCH_LIST_DEPARTMENT} from './actionsTypes'

export const FilterByCat = (filter_by_cat) => ({ 
    type: FILTER_BY_CATEGORY,
    filter_by_cat: filter_by_cat
});

export const FilterByService = (filter_by_serv) => ({ 
    type: FILTER_BY_SERVICES,
    filter_by_serv: filter_by_serv
});

export const FilterBySector = (filter_by_sec) => ({ 
    type: FILTER_BY_SECTORS,
    filter_by_sec: filter_by_sec
});

export const FilterByDepartment = (filter_by_depart) => ({ 
    type: FILTER_BY_DEPARTMENTS,
    filter_by_depart: filter_by_depart
});

export const FilterByLevels = (filter_by_level) => ({ 
    type: FILTER_BY_LEVELS,
    filter_by_level: filter_by_level
});

export const AllInsights = (all_insights) => ({ 
    type: ALL_INSIGHTS,
    all_insights: all_insights
});

export const SearchListDepart = (search_list_department) => ({ 
    
    type: SEARCH_LIST_DEPARTMENT,
    search_list_department: search_list_department
});

